import { getFromLocalStorage, ready, setToLocalStorage } from "#js/components/utils"
import { initPusher } from "#js/integrations/pusher"
import { fetchUserConfig } from "#js/components/config"

export async function initializePusher() {
  const disabledForBrowser = getFromLocalStorage("push-notifications-disabled", false)

  if (
    !disabledForBrowser &&
    Notification.permission === "granted"
  ) {
    await initPusher()
  }
}

export function requestBrowserPermissions() {
  globalThis.dataLayer.push({
    event: "request_push_notifications",
  })
  Notification.requestPermission().then(function (permission) {
    if (permission === "granted") {
      globalThis.dataLayer.push({
        event: "grant_push_notifications",
      })
      globalThis.location.reload()
    } else {
      globalThis.dataLayer.push({
        event: "deny_push_notifications",
      })
    }
  })
}

// handle permission requests including modal with help text
export async function initializePushNotificationModal() {
  const disabledForBrowser = getFromLocalStorage("push-notifications-disabled", false)

  const config = await fetchUserConfig()
  const hasPushNotificationsEnabled = Object.values(config.notifications).some((bool) =>
    bool
  )

  if (
    hasPushNotificationsEnabled && !disabledForBrowser &&
    Notification.permission !== "granted"
  ) {
    const pushNotificationModal = document.getElementById("push-notifications-dialog")
    pushNotificationModal.showModal()

    const modalEnable = document.getElementById("push-notifications-enable")
    const modalDisable = document.getElementById("push-notifications-disable")
    const modalDismiss = document.getElementById("push-notifications-dismiss")

    modalEnable.addEventListener("click", function (event) {
      event.preventDefault()
      requestBrowserPermissions()
    })

    modalDisable.addEventListener("click", function (event) {
      event.preventDefault()
      pushNotificationModal.close()
      setToLocalStorage("push-notifications-disabled", true)
    })

    modalDismiss.addEventListener("click", function (event) {
      event.preventDefault()
      pushNotificationModal.close()
    })
  }
}

if ("Notification" in window) {
  ready(initializePusher)
  ready(initializePushNotificationModal)
}

export default {
  initializePusher,
  initializePushNotificationsModal: initializePushNotificationModal,
}
